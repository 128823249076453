.custom-contact {
  width: 100%;
  max-width: 1000px;
  background: rgba(0, 0, 0, 0.3);
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  text-align: center;
  color: #fff; /* Ensure text is readable */
}

.custom-contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.custom-keep-in-touch {
  font-family: "Montserrat";
  font-weight: normal;
  font-size: 2em;
  margin-bottom: 20px;
}

.custom-inputRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.custom-inputRow .MuiTextField-root {
  flex: 1;
  margin: 10px;
  min-width: calc(50% - 20px);
}

.custom-messageField .MuiTextField-root {
  width: calc(100% - 20px); /* Make the message field span the entire width */
  margin: 10px;
}

.custom-submitButton {
  margin: 20px;
  width: 40%;
  background-color: black;
  color: white;
  cursor: pointer;
}

.custom-submitButton:hover {
  background-color: black;
  opacity: 0.3;
}

.custom-submitMessage {
  margin-top: 20px;
  color: green;
}

.custom-social-logos {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.custom-social-logos a {
  margin: 0 10px;
  background: rgba(255, 255, 255, 0.8); /* Slightly transparent background */
  padding: 14px;
  border-radius: 50%;
  border: solid #e0d4cc 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000; /* Ensure icons are visible */
}

@media (max-width: 640px) {
  .custom-contact {
    width: 100%;
    max-width: 1000px;
    background: rgba(
      0,
      0,
      0,
      0.3
    ); /* Match the background of the coming soon section */
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    text-align: center;
    color: #fff; /* Ensure text is readable */
    box-sizing: border-box; /* Ensure padding is included in the width */
    overflow: hidden; /* Prevent horizontal scroll */
  }

  .custom-contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .custom-keep-in-touch {
    font-family: "Montserrat";
    font-weight: normal;
    font-size: 2em;
    margin-bottom: 20px;
  }

  .custom-inputRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  .custom-inputRow .MuiTextField-root {
    flex: 1;
    margin: 10px;
    min-width: calc(50% - 20px);
  }

  .custom-messageField .MuiTextField-root {
    width: calc(100% - 20px); /* Make the message field span the entire width */
    margin: 10px;
  }

  .custom-submitButton {
    margin: 20px;
    width: 40%;
    background-color: black;
    color: white;
    cursor: pointer;
  }

  .custom-submitButton:hover {
    background-color: black;
    opacity: 0.3;
  }

  .custom-submitMessage {
    margin-top: 20px;
    color: green;
  }

  .custom-social-logos {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .custom-social-logos a {
    margin: 0 10px;
    background: rgba(255, 255, 255, 0.8); /* Slightly transparent background */
    padding: 14px;
    border-radius: 50%;
    border: solid #e0d4cc 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000; /* Ensure icons are visible */
  }
}
