.footer {
    background-color: #000;
    color: #fff;
    padding: 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-top: 2px;
  }
  
  .footer .contact-info,
  .footer .copyright {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .footer p {
    margin: 0;
  }
  