.services {
  text-align: center;
}

.services-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.services-container .MuiCard-root {
  margin: 10px;
}
