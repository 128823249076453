@font-face {
  font-family: "custom-cursive";
  src: url("./../assets/fonts/famulred/Famulred.otf");
}

@font-face {
  font-family: "Playfair-Display";
  src: url("./../assets/fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("./../assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  font-family: "Montserrat", sans-serif;
}

.coming-soon-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: url("../assets/Images/beach.jpg") no-repeat center center fixed;
  background-size: cover;
  color: #fff;
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 20px;
}

.coming-soon-container {
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  margin-top: 50px;
  width: calc(100% - 40px);
}

.coming-soon-logo {
  max-width: 300px;
  margin-bottom: 10px;
}

.coming-soon-text {
  font-family: "custom-cursive";
  font-size: 6em;
  font-weight: lighter;
  margin: 10px 0;
}

.coming-soon-form {
  margin: 10px 0;
}

.access-denied {
  color: red;
  margin-top: 10px;
}

.password-input-container {
  position: relative;
  display: inline-block;
}

.coming-soon-input {
  margin-top: 10px;
  padding: 10px;
  font-size: 1em;
  padding-right: 40px;
  border: none;
  border-radius: 5px;
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 60%;
  transform: translateY(-50%);
  cursor: pointer;
  color: black;
}

.coming-soon-button {
  border: solid gray 1px;
  color: black;
  padding: 10px 20px;
  font-size: 1em;
  margin-left: 5px;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 640px) {
  .coming-soon-text {
    font-size: 3em;
  }
  .coming-soon-page {
    background-attachment: scroll;
  }
  .coming-soon-container {
    overflow: hidden;
  }
}
