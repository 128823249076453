.home {
    text-align: center;
    margin: 50px auto;
    max-width: 800px;
    margin-bottom: 100px;
  }
  
  .home h1 {
    font-size: 2.5em;
    color: black;
  }
  
  .home p {
    font-size: 1.2em;
    color: #666;
  }
  .welcomBanner {
    padding: 40px;
    color: white; 
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .slogan {
    font-size: 1.35em;
    margin-top: 20px;
    white-space: nowrap;
    overflow: hidden; 
    border-right: .15em solid orange; 
    color: #666661;
    width: 34ch;
    animation: typing 4s steps(24), blink-caret .75s step-end infinite;
  }
  
  /* The typing animation */
  @keyframes typing {
    from { width: 0 }
    to { width: 34ch }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }
  @media (max-width: 640px) {
    .welcomBanner {
      padding: 0.8px;
      align-items: center;
      justify-content: center;
    }
    .home h1 {
      font-size: 1.5em;
    }
    .slogan {
      font-size: 1em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      }
      
  }