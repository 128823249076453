.about {
    text-align: center;
    margin: 50px auto;
    max-width: 800px;
  }
  
  .about h1 {
    font-size: 2em;
    color: #333;
  }
  
  .about p {
    font-size: 1.2em;
    color: #666;
  }
  