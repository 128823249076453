/* Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: solid #000 1px;
}

.navbar-logo {
  height: 90px;
}

.navbar-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: none; /* Initially hide the navbar links */
  justify-content: center;
  flex-grow: 1;
}

.navbar-nav li {
  margin: 0 15px;
}

.navbar-nav li a {
  color: #000;
  text-decoration: none;
  transition: color 0.1s ease, transform 0.1s ease;
  position: relative;
  overflow: hidden;
  padding: 10px 0;
}

.navbar-nav li a::before,
.navbar-nav li a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 0;
  background: #000;
  left: 0;
  transition: all 0.1s ease;
}

.navbar-nav li a:hover::before {
  top: 0;
}

.navbar-nav li a:hover::after {
  bottom: 0;
}

.navbar-nav li a:hover {
  color: #888;
  transform: scale(1.1);
}

.navbar-nav li a:hover::before,
.navbar-nav li a:hover::after {
  height: 1px;
}

.navbar-nav li a.active::before,
.navbar-nav li a.active::after {
  height: 1px;
}

/* hide the hamburger menu initially */
.hamburger {
  display: none !important;
}

/* For mobile view */
@media (max-width: 599px) {
  .hamburger {
      display: block !important;
  }
}

/* For desktop view */
@media (min-width: 600px) {
  .navbar-nav {
      display: flex;
  }
}
